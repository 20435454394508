const keresztelotortak = [                                                                              /* KERESZTELŐ TORTÁK */
    {
        id: 1,
        cake_img: require('../../images/christening_cakes/IMG_20211106_064433.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/christening_cakes/IMG_20211118_114004.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/christening_cakes/IMG_20211118_135743.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/christening_cakes/IMG_20211216_103508.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/christening_cakes/IMG_20220506_090154.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/christening_cakes/IMG_20220811_100245.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/christening_cakes/IMG_20220817_154747.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/christening_cakes/IMG_20220825_132058.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/christening_cakes/IMG_20220902_093209.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/christening_cakes/IMG_20220902_093353.jpg')

    },
    {
        id: 11,
        cake_img: require('../../images/christening_cakes/IMG_20220903_070849.jpg')

    },
    {
        id: 12,
        cake_img: require('../../images/christening_cakes/IMG_20221007_083135.jpg')

    },
]

export default keresztelotortak
const mousse = [                                                                               /* MOUSSE */
    {
        id: 1,
        cake_img: require('../../images/mousse_cakes/IMG_20210723_121226.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/mousse_cakes/IMG_20210827_184155.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/mousse_cakes/IMG_20210924_094903.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/mousse_cakes/IMG_20211004_092507.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/mousse_cakes/IMG_20211028_152826.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/mousse_cakes/IMG_20220611_062017.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/mousse_cakes/IMG_20220617_143507.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/mousse_cakes/IMG_20220722_135434.jpg')
    },
    {
        id: 9,
        cake_img: require('../../images/mousse_cakes/IMG_20220818_114108.jpg')
    },
    {
        id: 9,
        cake_img: require('../../images/mousse_cakes/IMG_20230105_124542.jpg')
    },
    {
        id: 10,
        cake_img: require('../../images/mousse_cakes/IMG_20230203_115648.jpg')
    },
    {
        id: 11,
        cake_img: require('../../images/mousse_cakes/IMG_20230203_133023.jpg')
    },
    {
        id: 12,
        cake_img: require('../../images/mousse_cakes/IMG_20230203_132918.jpg')
    },
    {
        id: 13,
        cake_img: require('../../images/mousse_cakes/IMG_20230217_073253.jpg')
    },
    {
        id: 14,
        cake_img: require('../../images/mousse_cakes/IMG_20230217_072734.jpg')
    },
    {
        id: 15,
        cake_img: require('../../images/mousse_cakes/IMG_20230214_075152.jpg')
    },
    {
        id: 16,
        cake_img: require('../../images/mousse_cakes/IMG_20240329_084343.jpg')
    }

]

export default mousse
export default [

    /* {
        id: 1,
        slider_image: require('../images/image_slider/magyarorszag_tortaja_2023.jpg')
    },
    {
        id: 2,
        slider_image: require('../images/image_slider/magyarorszag_tortaja2_2023.jpg')
    }, */
    {
        id: 3,
        slider_image: require('../images/image_slider/20221203003905__MG_2580.jpg')
    },
    {
        id: 4,
        slider_image: require('../images/image_slider/320920117_1690768218417197_4999327438167329210_n.jpg')
    },
    {
        id: 5,
        slider_image: require('../images/image_slider/ALD_9865.jpg')
    },
    {
        id: 6,
        slider_image: require('../images/image_slider/ALD_9867.jpg')
    },
    {
        id: 7,
        slider_image: require('../images/image_slider/ALD_9869.jpg')
    },
    {
        id: 8,
        slider_image: require('../images/image_slider/ALD_9870.jpg')
    },
    {
        id: 9,
        slider_image: require('../images/image_slider/ALD_9871.jpg')
    },
    {
        id: 10,
        slider_image: require('../images/image_slider/ALD_9872.jpg')
    },
    {
        id: 11,
        slider_image: require('../images/image_slider/ALD_9873.jpg')
    },
    {
        id: 12,
        slider_image: require('../images/image_slider/ALD_9874.jpg')
    },
    {
        id: 13,
        slider_image: require('../images/image_slider/ALD_9876.jpg')
    },
    {
        id: 14,
        slider_image: require('../images/image_slider/ALD_9877.jpg')
    },
    {
        id: 15,
        slider_image: require('../images/image_slider/ALD_9864.jpg')
    },


]
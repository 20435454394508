const bonbonok = [                                                                       /* BONBONOK */
    {
        id: 1,
        cake_img: require('../../images/bonbons/bonbon1.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/bonbons/bonbon2.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/bonbons/csokoládé desszertek.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/bonbons/csokoládé desszertek2.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/bonbons/IMG_20220120_190320.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/bonbons/IMG_20220418_092045.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/bonbons/IMG_20220426_155239.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/bonbons/IMG_20220426_155310.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/bonbons/IMG_20220426_155317.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/bonbons/IMG_20220426_155324.jpg')

    },
    {
        id: 11,
        cake_img: require('../../images/bonbons/IMG_20220418_092030.jpg')

    },
]

export default bonbonok 
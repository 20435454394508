const desszertek = [                                                                              /* DESSZERTEK */
    {
        id: 1,
        cake_img: require('../../images/desserts/319331065_569064938444061_7041365258458857386_n.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/desserts/320673916_542369894515990_1584463719968201243_n.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/desserts/320692213_1361846814566912_6350477130049641304_n.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/desserts/320736374_900563880973606_5102944647138263270_n.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/desserts/320920117_1690768218417197_4999327438167329210_n.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/desserts/320977513_470411161973569_2861705945143506383_n.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/desserts/321240787_1563225627482315_81465037390845411_n.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/desserts/324828276_923936148767331_6575189742329300662_n.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/desserts/325150314_3477954819106681_5717529670236439474_n.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/desserts/325150314_6504511459563784_7083556574201451257_n.jpg')

    },
    {
        id: 11,
        cake_img: require('../../images/desserts/325179248_496651015929246_9004244833458486048_n.jpg')

    },
    {
        id: 12,
        cake_img: require('../../images/desserts/325442705_729687531792429_319055971458430421_n.jpg')

    },
    {
        id: 13,
        cake_img: require('../../images/desserts/325453341_919903652500277_1271822390566419787_n.jpg')

    },
    {
        id: 14,
        cake_img: require('../../images/desserts/325464745_1073988180658558_452249825248513071_n.jpg')

    },
    {
        id: 15,
        cake_img: require('../../images/desserts/325473137_719904556312086_1030090217407569625_n.jpg')

    },
    {
        id: 16,
        cake_img: require('../../images/desserts/325475927_1581284842285563_4210855857753721268_n.jpg')

    },
    {
        id: 17,
        cake_img: require('../../images/desserts/325479444_3328296244153587_491008868074320008_n.jpg')

    },
    {
        id: 18,
        cake_img: require('../../images/desserts/325481357_838287677244195_6045360245142949427_n.jpg')

    },
    {
        id: 19,
        cake_img: require('../../images/desserts/325505602_746471920336660_7658956117114834610_n.jpg')

    },
    {
        id: 20,
        cake_img: require('../../images/desserts/325512924_871087584214533_1094599023685613253_n.jpg')

    },
    {
        id: 21,
        cake_img: require('../../images/desserts/325526688_861516235074145_2966599403682986013_n.jpg')

    },
    {
        id: 22,
        cake_img: require('../../images/desserts/325598198_943076263264166_3779796078902941806_n.jpg')

    },
    {
        id: 23,
        cake_img: require('../../images/desserts/325635020_486068150378652_261409709705533974_n.jpg')

    },
    {
        id: 24,
        cake_img: require('../../images/desserts/IMG_20220506_095903.jpg')

    },
    {
        id: 25,
        cake_img: require('../../images/desserts/IMG_20220611_071123.jpg')

    },

]

export default desszertek
const eskuvoitortak = [                                                                          /* ESKÜVŐI TORTÁK */
    {
        id: 1,
        cake_img: require('../../images/wedding_cakes/IMG_20210407_090101.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/wedding_cakes/IMG_20210819_074315.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/wedding_cakes/IMG_20210819_074507.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/wedding_cakes/IMG_20220129_125204.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/wedding_cakes/IMG_20220606_094538.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/wedding_cakes/IMG_20220730_120132.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/wedding_cakes/IMG_20220805_193907.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/wedding_cakes/IMG_20220909_102755.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/wedding_cakes/házassági évforduló.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/wedding_cakes/házassági évfordulós.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/wedding_cakes/IMG_20240223_131345.jpg')

    },
]

export default eskuvoitortak
const gyerektortak = [                                                                               /* GYEREKTORTÁK */
    {
        id: 1,
        cake_img: require('../../images/children_cakes/FB_IMG_1594008939341.jpg')

    },
    {
        id: 2,
        cake_img: require('../../images/children_cakes/IMG_20200725_150739.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/children_cakes/IMG_20210709_084416.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/children_cakes/IMG_20210730_103037.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/children_cakes/IMG_20210807_071336.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/children_cakes/IMG_20211009_121758.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/children_cakes/IMG_20211009_145904.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/children_cakes/IMG_20211010_085251.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/children_cakes/IMG_20211016_131639.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/children_cakes/IMG_20211023_110204.jpg')

    },
    {
        id: 11,
        cake_img: require('../../images/children_cakes/IMG_20211023_121047.jpg')

    },
    {
        id: 12,
        cake_img: require('../../images/children_cakes/IMG_20211029_070643.jpg')

    },
    {
        id: 13,
        cake_img: require('../../images/children_cakes/IMG_20211117_155156.jpg')

    },
    {
        id: 14,
        cake_img: require('../../images/children_cakes/IMG_20211119_062848.jpg')

    },
    {
        id: 15,
        cake_img: require('../../images/children_cakes/IMG_20211210_164034.jpg')

    },
    {
        id: 16,
        cake_img: require('../../images/children_cakes/IMG_20211217_130318.jpg')

    },
    {
        id: 17,
        cake_img: require('../../images/children_cakes/IMG_20211218_083848.jpg')

    },
    {
        id: 18,
        cake_img: require('../../images/children_cakes/IMG_20211223_090238.jpg')

    },
    {
        id: 19,
        cake_img: require('../../images/children_cakes/IMG_20211223_103657.jpg')

    },
    {
        id: 20,
        cake_img: require('../../images/children_cakes/IMG_20211224_044852.jpg')

    },
    {
        id: 21,
        cake_img: require('../../images/children_cakes/IMG_20220101_095414.jpg')

    },
    {
        id: 22,
        cake_img: require('../../images/children_cakes/IMG_20220107_092829.jpg')

    },
    {
        id: 23,
        cake_img: require('../../images/children_cakes/IMG_20220107_122738.jpg')

    },
    {
        id: 24,
        cake_img: require('../../images/children_cakes/IMG_20220108_103119.jpg')

    },
    {
        id: 25,
        cake_img: require('../../images/children_cakes/IMG_20220111_124302.jpg')

    },
    {
        id: 26,
        cake_img: require('../../images/children_cakes/IMG_20220113_113125.jpg')

    },
    {
        id: 27,
        cake_img: require('../../images/children_cakes/IMG_20220128_081554.jpg')

    },
    {
        id: 28,
        cake_img: require('../../images/children_cakes/IMG_20220129_103817.jpg')

    },
    {
        id: 29,
        cake_img: require('../../images/children_cakes/IMG_20220204_140140.jpg')

    },
    {
        id: 30,
        cake_img: require('../../images/children_cakes/IMG_20220208_114957.jpg')

    },
    {
        id: 31,
        cake_img: require('../../images/children_cakes/IMG_20220304_084116.jpg')

    },
    {
        id: 32,
        cake_img: require('../../images/children_cakes/IMG_20220315_132302.jpg')

    },
    {
        id: 33,
        cake_img: require('../../images/children_cakes/IMG_20220317_134522.jpg')

    },
    {
        id: 34,
        cake_img: require('../../images/children_cakes/IMG_20220323_080035.jpg')

    },
    {
        id: 35,
        cake_img: require('../../images/children_cakes/IMG_20220325_084015.jpg')

    },
    {
        id: 36,
        cake_img: require('../../images/children_cakes/IMG_20220405_065157.jpg')

    },
    {
        id: 37,
        cake_img: require('../../images/children_cakes/IMG_20220407_080556.jpg')

    },
    {
        id: 38,
        cake_img: require('../../images/children_cakes/IMG_20220407_090623.jpg')

    },
    {
        id: 39,
        cake_img: require('../../images/children_cakes/IMG_20220415_104445.jpg')

    },
    {
        id: 40,
        cake_img: require('../../images/children_cakes/IMG_20220422_093331.jpg')

    },
    {
        id: 41,
        cake_img: require('../../images/children_cakes/IMG_20220423_084508.jpg')

    },
    {
        id: 42,
        cake_img: require('../../images/children_cakes/IMG_20220520_091913.jpg')

    },
    {
        id: 43,
        cake_img: require('../../images/children_cakes/IMG_20220520_155308.jpg')

    },
    {
        id: 44,
        cake_img: require('../../images/children_cakes/IMG_20220526_092000.jpg')

    },
    {
        id: 45,
        cake_img: require('../../images/children_cakes/IMG_20220528_073216.jpg')

    },
    {
        id: 46,
        cake_img: require('../../images/children_cakes/IMG_20220528_091330.jpg')

    },
    {
        id: 47,
        cake_img: require('../../images/children_cakes/IMG_20220602_114101.jpg')

    },
    {
        id: 48,
        cake_img: require('../../images/children_cakes/IMG_20220603_073058.jpg')

    },
    {
        id: 49,
        cake_img: require('../../images/children_cakes/IMG_20220613_091639.jpg')

    },
    {
        id: 50,
        cake_img: require('../../images/children_cakes/IMG_20220617_113719.jpg')

    },
    {
        id: 51,
        cake_img: require('../../images/children_cakes/IMG_20220618_083921.jpg')

    },
    {
        id: 52,
        cake_img: require('../../images/children_cakes/IMG_20220624_101517.jpg')

    },
    {
        id: 53,
        cake_img: require('../../images/children_cakes/IMG_20220624_171519.jpg')

    },
    {
        id: 54,
        cake_img: require('../../images/children_cakes/IMG_20220628_074935.jpg')

    },
    {
        id: 55,
        cake_img: require('../../images/children_cakes/IMG_20220629_150505.jpg')

    },
    {
        id: 56,
        cake_img: require('../../images/children_cakes/IMG_20220701_091530.jpg')

    },
    {
        id: 57,
        cake_img: require('../../images/children_cakes/IMG_20220701_110333.jpg')

    },
    {
        id: 58,
        cake_img: require('../../images/children_cakes/IMG_20220708_091002.jpg')

    },
    {
        id: 59,
        cake_img: require('../../images/children_cakes/IMG_20220713_132451.jpg')

    },
    {
        id: 60,
        cake_img: require('../../images/children_cakes/IMG_20220715_080115.jpg')

    },
    {
        id: 61,
        cake_img: require('../../images/children_cakes/IMG_20220718_081257.jpg')

    },
    {
        id: 62,
        cake_img: require('../../images/children_cakes/IMG_20220718_081318.jpg')

    },
    {
        id: 63,
        cake_img: require('../../images/children_cakes/IMG_20220722_111228.jpg')

    },
    {
        id: 64,
        cake_img: require('../../images/children_cakes/IMG_20220728_120729.jpg')

    },
    {
        id: 65,
        cake_img: require('../../images/children_cakes/IMG_20220729_081204.jpg')

    },
    {
        id: 66,
        cake_img: require('../../images/children_cakes/IMG_20220811_155411.jpg')

    },
    {
        id: 67,
        cake_img: require('../../images/children_cakes/IMG_20220811_162722.jpg')

    },
    {
        id: 68,
        cake_img: require('../../images/children_cakes/IMG_20220827_072708.jpg')

    },
    {
        id: 69,
        cake_img: require('../../images/children_cakes/IMG_20220829_081809.jpg')

    },
    {
        id: 70,
        cake_img: require('../../images/children_cakes/IMG_20220906_083117.jpg')

    },
    {
        id: 71,
        cake_img: require('../../images/children_cakes/IMG_20220907_121401.jpg')

    },
    {
        id: 72,
        cake_img: require('../../images/children_cakes/IMG_20220908_112217.jpg')

    },
    {
        id: 73,
        cake_img: require('../../images/children_cakes/IMG_20220910_100500.jpg')

    },
    {
        id: 74,
        cake_img: require('../../images/children_cakes/IMG_20220910_111200.jpg')

    },
    {
        id: 75,
        cake_img: require('../../images/children_cakes/IMG_20220916_113549.jpg')

    },
    {
        id: 76,
        cake_img: require('../../images/children_cakes/IMG_20220923_171609.jpg')

    },
    {
        id: 77,
        cake_img: require('../../images/children_cakes/IMG_20221006_120229.jpg')

    },
    {
        id: 78,
        cake_img: require('../../images/children_cakes/received_404352603712083.jpg')

    },
    {
        id: 79,
        cake_img: require('../../images/children_cakes/received_578772259451966.jpg')

    },
    {
        id: 80,
        cake_img: require('../../images/children_cakes/received_782345412195389.jpg')

    },
    {
        id: 81,
        cake_img: require('../../images/children_cakes/IMG_20200229_082612.jpg')

    },
    {
        id: 82,
        cake_img: require('../../images/children_cakes/IMG_20200104_134037.jpg')

    },
    {
        id: 83,
        cake_img: require('../../images/children_cakes/IMG_20221118_092931.jpg')

    },
    {
        id: 84,
        cake_img: require('../../images/children_cakes/IMG_20221111_112527.jpg')

    },
    {
        id: 85,
        cake_img: require('../../images/children_cakes/IMG_20221112_082645.jpg')

    },
    {
        id: 86,
        cake_img: require('../../images/children_cakes/IMG_20221118_063733.jpg')

    },
    {
        id: 87,
        cake_img: require('../../images/children_cakes/IMG_20221104_135351.jpg')

    },
    {
        id: 88,
        cake_img: require('../../images/children_cakes/IMG_20221105_115102.jpg')

    },
    {
        id: 89,
        cake_img: require('../../images/children_cakes/IMG_20230203_093510.jpg')

    },
    {
        id: 90,
        cake_img: require('../../images/children_cakes/IMG_20230128_084630.jpg')

    },
    {
        id: 91,
        cake_img: require('../../images/children_cakes/IMG_20230211_133235.jpg')

    },
    {
        id: 92,
        cake_img: require('../../images/children_cakes/IMG_20230205_081459.jpg')

    },
    {
        id: 93,
        cake_img: require('../../images/children_cakes/IMG_20230217_093036.jpg')

    },
    {
        id: 94,
        cake_img: require('../../images/children_cakes/IMG_20230218_070620.jpg')

    },
    {
        id: 95,
        cake_img: require('../../images/children_cakes/IMG_20240315_104851.jpg')

    },
    {
        id: 96,
        cake_img: require('../../images/children_cakes/IMG_20240223_093454.jpg')

    },
    {
        id: 97,
        cake_img: require('../../images/children_cakes/IMG_20240302_091444.jpg')

    },
    {
        id: 98,
        cake_img: require('../../images/children_cakes/IMG_20240316_070815.jpg')

    }
]

export default gyerektortak
const formatortak = [                                                                           /* FORMATORTÁK */
    {
        id: 1,
        cake_img: require('../../images/cakes/IMG_20220611_100317.jpg'),
        text: "A Szent Korona"

    },
    {
        id: 2,
        cake_img: require('../../images/cakes/IMG_20210126_202640.jpg')

    },
    {
        id: 3,
        cake_img: require('../../images/cakes/IMG_20210213_140912.jpg')

    },
    {
        id: 4,
        cake_img: require('../../images/cakes/IMG_20210521_073414.jpg')

    },
    {
        id: 5,
        cake_img: require('../../images/cakes/IMG_20210521_100336.jpg')

    },
    {
        id: 6,
        cake_img: require('../../images/cakes/IMG_20210521_124256.jpg')

    },
    {
        id: 7,
        cake_img: require('../../images/cakes/IMG_20210521_142827.jpg')

    },
    {
        id: 8,
        cake_img: require('../../images/cakes/IMG_20210605_081717.jpg')

    },
    {
        id: 9,
        cake_img: require('../../images/cakes/IMG_20210605_090325.jpg')

    },
    {
        id: 10,
        cake_img: require('../../images/cakes/IMG_20210611_092629.jpg')

    },
    {
        id: 11,
        cake_img: require('../../images/cakes/IMG_20210617_172035.jpg')

    },
    {
        id: 12,
        cake_img: require('../../images/cakes/IMG_20210621_193018.jpg')

    },
    {
        id: 13,
        cake_img: require('../../images/cakes/IMG_20210709_072512.jpg')

    },
    {
        id: 14,
        cake_img: require('../../images/cakes/IMG_20210709_100439.jpg')

    },
    {
        id: 15,
        cake_img: require('../../images/cakes/IMG_20210710_072316.jpg')

    },
    {
        id: 16,
        cake_img: require('../../images/cakes/IMG_20210718_105123.jpg')

    },
    {
        id: 17,
        cake_img: require('../../images/cakes/IMG_20210723_092618.jpg')

    },
    {
        id: 18,
        cake_img: require('../../images/cakes/IMG_20210730_074004.jpg')

    },
    {
        id: 19,
        cake_img: require('../../images/cakes/IMG_20210803_082741.jpg')

    },
    {
        id: 20,
        cake_img: require('../../images/cakes/IMG_20210811_113727.jpg')

    },
    {
        id: 21,
        cake_img: require('../../images/cakes/IMG_20210819_110710.jpg')

    },
    {
        id: 22,
        cake_img: require('../../images/cakes/IMG_20210819_110731.jpg')

    },
    {
        id: 23,
        cake_img: require('../../images/cakes/IMG_20210819_134819.jpg')

    },
    {
        id: 24,
        cake_img: require('../../images/cakes/IMG_20210827_193437.jpg')

    },
    {
        id: 25,
        cake_img: require('../../images/cakes/IMG_20210926_102432.jpg')

    },
    {
        id: 26,
        cake_img: require('../../images/cakes/IMG_20211001_101507.jpg')

    },
    {
        id: 27,
        cake_img: require('../../images/cakes/IMG_20211009_105251.jpg')

    },
    {
        id: 28,
        cake_img: require('../../images/cakes/IMG_20211016_150145.jpg')

    },
    {
        id: 29,
        cake_img: require('../../images/cakes/IMG_20211017_111621.jpg')

    },
    {
        id: 30,
        cake_img: require('../../images/cakes/IMG_20211023_095913.jpg')

    },
    {
        id: 31,
        cake_img: require('../../images/cakes/IMG_20211104_113333.jpg')

    },
    {
        id: 32,
        cake_img: require('../../images/cakes/IMG_20211105_090355.jpg')

    },
    {
        id: 33,
        cake_img: require('../../images/cakes/IMG_20211105_110734.jpg')

    },
    {
        id: 34,
        cake_img: require('../../images/cakes/IMG_20211118_173526.jpg')

    },
    {
        id: 35,
        cake_img: require('../../images/cakes/IMG_20211120_163412.jpg')

    },
    {
        id: 36,
        cake_img: require('../../images/cakes/IMG_20211121_081601.jpg')

    },
    {
        id: 37,
        cake_img: require('../../images/cakes/IMG_20211126_085948.jpg')

    },
    {
        id: 38,
        cake_img: require('../../images/cakes/IMG_20211204_211932.jpg')

    },
    {
        id: 39,
        cake_img: require('../../images/cakes/IMG_20211204_212153.jpg')

    },
    {
        id: 40,
        cake_img: require('../../images/cakes/IMG_20211204_212219.jpg')

    },
    {
        id: 41,
        cake_img: require('../../images/cakes/IMG_20211215_092250.jpg')

    },
    {
        id: 42,
        cake_img: require('../../images/cakes/IMG_20211215_121251.jpg')

    },
    {
        id: 43,
        cake_img: require('../../images/cakes/IMG_20211223_130314.jpg')

    },
    {
        id: 44,
        cake_img: require('../../images/cakes/IMG_20220104_104658.jpg')

    },
    {
        id: 45,
        cake_img: require('../../images/cakes/IMG_20220112_074600.jpg')

    },
    {
        id: 46,
        cake_img: require('../../images/cakes/IMG_20220126_164053.jpg')

    },
    {
        id: 47,
        cake_img: require('../../images/cakes/IMG_20220204_120858.jpg')

    },
    {
        id: 48,
        cake_img: require('../../images/cakes/IMG_20220208_130500.jpg')

    },
    {
        id: 49,
        cake_img: require('../../images/cakes/IMG_20220219_073629.jpg')

    },
    {
        id: 50,
        cake_img: require('../../images/cakes/IMG_20220225_103713.jpg')

    },
    {
        id: 51,
        cake_img: require('../../images/cakes/IMG_20220227_101638.jpg')

    },
    {
        id: 52,
        cake_img: require('../../images/cakes/IMG_20220317_140857.jpg')

    },
    {
        id: 53,
        cake_img: require('../../images/cakes/IMG_20220318_170233.jpg')

    },
    {
        id: 54,
        cake_img: require('../../images/cakes/IMG_20220320_070904.jpg')

    },
    {
        id: 55,
        cake_img: require('../../images/cakes/IMG_20220408_130323.jpg')

    },
    {
        id: 56,
        cake_img: require('../../images/cakes/IMG_20220422_121404.jpg')

    },
    {
        id: 57,
        cake_img: require('../../images/cakes/IMG_20220423_121821.jpg')

    },
    {
        id: 58,
        cake_img: require('../../images/cakes/IMG_20220519_082915.jpg')

    },
    {
        id: 59,
        cake_img: require('../../images/cakes/IMG_20220521_071404.jpg')

    },
    {
        id: 60,
        cake_img: require('../../images/cakes/FB_IMG_1613247161294.jpg')

    },
    {
        id: 61,
        cake_img: require('../../images/cakes/IMG_20220617_093745.jpg')

    },
    {
        id: 62,
        cake_img: require('../../images/cakes/IMG_20220630_164627.jpg')

    },
    {
        id: 63,
        cake_img: require('../../images/cakes/IMG_20220708_084426.jpg')

    },
    {
        id: 64,
        cake_img: require('../../images/cakes/IMG_20220718_085754.jpg')

    },
    {
        id: 65,
        cake_img: require('../../images/cakes/IMG_20220721_074658.jpg')

    },
    {
        id: 66,
        cake_img: require('../../images/cakes/IMG_20220723_123655.jpg')

    },
    {
        id: 67,
        cake_img: require('../../images/cakes/IMG_20220730_120546.jpg')

    },
    {
        id: 68,
        cake_img: require('../../images/cakes/IMG_20220910_061006.jpg')

    },
    {
        id: 69,
        cake_img: require('../../images/cakes/received_443832086808901.jpeg')

    },
    {
        id: 70,
        cake_img: require('../../images/cakes/received_847930352365428.jpeg')

    },
    {
        id: 71,
        cake_img: require('../../images/cakes/received_1014173945678755.jpeg')

    },
    {
        id: 72,
        cake_img: require('../../images/cakes/received_1386935114985247.jpeg')

    },
    {
        id: 73,
        cake_img: require('../../images/cakes/IMG_20200305_192819.jpg')

    },
    {
        id: 74,
        cake_img: require('../../images/cakes/IMG_20191207_175040.jpg')

    },
    {
        id: 75,
        cake_img: require('../../images/cakes/IMG_20191207_175217.jpg')

    },
    {
        id: 76,
        cake_img: require('../../images/cakes/IMG_20191212_144644.jpg')

    },
    {
        id: 77,
        cake_img: require('../../images/cakes/IMG_20190906_171936.jpg')

    },
    {
        id: 78,
        cake_img: require('../../images/cakes/IMG_20191129_174916.jpg')

    },
    {
        id: 79,
        cake_img: require('../../images/cakes/IMG_20221103_093243.jpg')

    },
    {
        id: 80,
        cake_img: require('../../images/cakes/IMG_20200605_175420.jpg')

    },
    {
        id: 81,
        cake_img: require('../../images/cakes/IMG_20200626_204642.jpg')

    },
    {
        id: 82,
        cake_img: require('../../images/cakes/IMG_20220126_153856.jpg')

    },
    {
        id: 83,
        cake_img: require('../../images/cakes/IMG_20220401_174257.jpg')

    },
    {
        id: 84,
        cake_img: require('../../images/cakes/IMG_20220407_090639.jpg')

    },
    {
        id: 85,
        cake_img: require('../../images/cakes/IMG_20201128_060009.jpg')

    },
    {
        id: 86,
        cake_img: require('../../images/cakes/IMG_20201207_151017.jpg')

    },
    {
        id: 87,
        cake_img: require('../../images/cakes/IMG_20201207_151319.jpg')

    },
    {
        id: 88,
        cake_img: require('../../images/cakes/IMG_20201211_080842.jpg')

    },
    {
        id: 89,
        cake_img: require('../../images/cakes/IMG_20201220_075012.jpg')

    },
    {
        id: 90,
        cake_img: require('../../images/cakes/IMG_20201225_135834.jpg')

    },
    {
        id: 91,
        cake_img: require('../../images/cakes/IMG_20230125_132142.jpg')

    },
    {
        id: 92,
        cake_img: require('../../images/cakes/IMG_20230120_061600.jpg')

    },
    {
        id: 93,
        cake_img: require('../../images/cakes/IMG_20230203_074015.jpg')

    },
    {
        id: 94,
        cake_img: require('../../images/cakes/IMG_20230202_143127.jpg')

    },
    {
        id: 95,
        cake_img: require('../../images/cakes/IMG_20230210_082656.jpg')

    },
    {
        id: 96,
        cake_img: require('../../images/cakes/IMG_20230216_125326.jpg')

    },
    {
        id: 97,
        cake_img: require('../../images/cakes/IMG_20240404_092650.jpg')

    },
    {
        id: 98,
        cake_img: require('../../images/cakes/IMG_20240224_165005.jpg')

    },
    {
        id: 99,
        cake_img: require('../../images/cakes/IMG_20240328_093221.jpg')

    },
    {
        id: 100,
        cake_img: require('../../images/cakes/IMG_20240329_134947.jpg')

    },
    {
        id: 101,
        cake_img: require('../../images/cakes/IMG_20240321_094232.jpg')

    },
    {
        id: 102,
        cake_img: require('../../images/cakes/IMG_20240314_113055.jpg')

    }
]

export default formatortak